<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="余额管理" name="first">
        <div class="big">
          <!-- <div>
            <p class="c6">账户余额</p>
            <p>￥{{price.price4}}</p>
          </div> -->
          <div>
            <p class="c6">消费付款</p>
            <p>￥{{price.price1}}</p>
          </div>
          <!-- <div>
            <p class="c6">退款出账</p>
            <p>￥{{price.price2}}</p>
          </div>
          <div>
            <p class="c6">退款入户</p>
            <p>￥{{price.price3}}</p>
          </div> -->
        </div>
        <el-form :inline="true" :model="form1" style="margin-top: 20px">
          <el-form-item label="相关订单:">
            <el-input style="width: 150px" v-model="form1.ddbh" placeholder="相关订单"></el-input>
          </el-form-item>
          <el-form-item label="财务类型:">
            <el-select style="width: 150px" :clearable="true" v-model="form1.cwlx" placeholder="财务类型">
              <el-option v-for="item in cwlxOption" :key="item.id" :label="item.value" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发生时间:">
            <el-date-picker style="width: 340px" v-model="value1" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="custombtn" @click="getBillData">筛选</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="tableData">
          <!-- <el-table-column type="index" width="50" label="序号" align="center" /> -->
          <el-table-column prop="id" label="序号" align="center" />
          <el-table-column prop="orderno" label="相关订单" align="center" />
          <el-table-column prop="title" label="财务类型" align="center" />
          <el-table-column prop="money" label="发生金额" align="center" />
          <el-table-column prop="note" label="备注" align="center" />
          <el-table-column prop="date_str" min-width="100px" label="发生时间" align="center" />
        </el-table>
      </el-tab-pane>

      <!-- <el-tab-pane label="提现" name="second">
        <div class="second">
          <el-form ref="form" :model="form2" label-width="100px">
            <el-form-item label="提现余额：">
              <div class="flex_row">
                <el-input style="width: 120px" v-model="form2.price"></el-input>
              </div>
            </el-form-item>
            <el-form-item label="退款账户：">
              <el-input style="width: 220px" v-model="form2.tkzh"></el-input>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input type="textarea" v-model="form2.bz"></el-input>
            </el-form-item>
            
            <el-form-item>
              <el-button type="primary" class="custombtn" @click="ontxSubmit">确认</el-button>
            </el-form-item>
          </el-form>

          
        </div>
      </el-tab-pane> -->
      <!-- <el-table :data="tableData" class="mt_20">
            <el-table-column prop="date" label="审核状态" align="center" />
            <el-table-column prop="name" label="提现时间" align="center" />
            <el-table-column prop="name" label="审核时间" align="center" />
            <el-table-column prop="name" label="提现方式" align="center" />
            <el-table-column prop="name" label="账户名称" align="center" />
            <el-table-column prop="name" label="提现金额(元)" align="center" />
            <el-table-column prop="name" label="备注" align="center" />
        </el-table> -->

      <!-- <el-tab-pane label="充值" name="third">
        <el-form ref="form" :model="form2" label-width="100px">
          <el-form-item label="充值金额：">
            <el-input style="width: 200px" v-model="form2.name"></el-input>
            <div class="flex_row item_wrap">
              <div class="item flex_c_c c3" :class="currentI === i ? 'active' : ''" @click="handleChoose(i)" v-for="(item, i) in list" :key="item">{{ item }}元</div>
            </div>
          </el-form-item>
          <el-form-item label="充值方式：">
            <el-radio-group class="group" v-model="form2.resource">
              <el-radio label="1"> <i class="el-icon-burger"></i> 微信支付</el-radio>
              <el-radio label="2">支付宝支付</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 180px" type="primary" class="custombtn" @click="onSubmit"> 立即充值 </el-button>
          </el-form-item>
        </el-form>
        <p class="agreen c9 ml_20">
          点击立即充值，即表示已阅读并同意
          <span @click="dialogVisible = true">《充值协议》</span>
        </p>
        <el-tabs v-model="activeName1" class="mt_20">
          <el-tab-pane label="充值规则" name="first">
            <div class="rules">
              <p>充值50000元余额到账50000</p>
              <p>充值10000元余额到账10000</p>
              <p>充值5000元余额到账5000</p>
              <p>充值3000元余额到账3000</p>
              <p>充值1000元余额到账1000</p>
              <p>充值500元余额到账500</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="充值记录" name="second"></el-tab-pane>
        </el-tabs>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="申请退款" name="fourth"></el-tab-pane> -->
    </el-tabs>
    <!-- <pagination v-if="activeName == 'first' || activeName == 'second' || activeName1 == 'second'" /> -->
    <pagination v-if="activeName == 'first' || activeName1 == 'second'"
    :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />

    <el-dialog :lock-scroll="false" title="充值协议" :visible.sync="dialogVisible" width="50%" center>
      <ul>
        <li>
          尊敬的用户，为保障您的合法权益，请您在充值之前仔细阅读、理解并接受本规则。当您在服务页面点击“立即充值”按钮后，即视为您已阅读、理解本规则，并同意按本规则的规定参与充值、充值返利及类似性质的活动。
        </li>
        <li>
          尊敬的用户，为保障您的合法权益，请您在充值之前仔细阅读、理解并接受本规则。当您在服务页面点击“立即充值”按钮后，即视为您已阅读、理解本规则，并同意按本规则的规定参与充值、充值返利及类似性质的活动。
        </li>
        <li>
          尊敬的用户，为保障您的合法权益，请您在充值之前仔细阅读、理解并接受本规则。当您在服务页面点击“立即充值”按钮后，即视为您已阅读、理解本规则，并同意按本规则的规定参与充值、充值返利及类似性质的活动。
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="custombtn" type="primary" @click="dialogVisible = false">我已阅读</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {

      cwlxOption:{},
      activeName: "first",
      activeName1: "first",
      form1: {},
      form2: {
        price: "", //退款金额
        bz: "", //备注
        tkzh: "", //退款账户
      },
      value1: "",
      tableData: [],
     
      value: "",
      // list: ["50000.00", "10000.00", "5000.00", "3000.00", "1000.00", "500.00"],
      price: {price1:0.00,price2:0.00,price3:0.00,price4:0.00},
      currentI: 0,
      dialogVisible: false,
      total: 0,
      page: 1,
      page_size: 10,
    };
  },
  created() {

    this.billAttribute()
    this.getBillData()
    
  },
  methods: {

    // 余额管理属性
    billAttribute(){
      this.$api("account.getBillAttribute",{
        ...this.form1,
        qssj: this.value1 ? Date.parse(this.value1[0]) : "",
        jssj: this.value1 ? Date.parse(this.value1[1]) : "",
        page:this.page,
        page_size:this.page_size
      }).then((res) => {
        this.price = res.data.ct
        this.cwlxOption = res.data.cwlx
      });
    },

    // 余额管理列表
    getBillData(){

      this.$api("account.getBill",{
        ...this.form1,
        qssj: this.value1 ? Date.parse(this.value1[0]) : "",
        jssj: this.value1 ? Date.parse(this.value1[1]) : "",
        page:this.page,
        page_size:this.page_size
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal
        this.billAttribute()
      });
    },

    handleClick(tab, event) {
      console.log(tab, event);
      this.getBillData()
    },
    onSubmit() {},
    //提现申请
    ontxSubmit() {
      this.$api("account.fetchBalance", this.form2).then(() => {
        this.$message.success("已提现");
        this.getBillData()
        this.activeName = "first";
        this.form2={
          price: "", //退款金额
          bz: "", //备注
          tkzh: "", //退款账户
        }
      });
    },
    handleChoose(i) {
      this.currentI = i;
      
    },

    handleCurrentChange(val) {
      this.page = val;
      this.getBillData();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getBillData();
    },
  },
};
</script>

<style lang="less" scoped>
.big {
  width: 950px;
  height: 115px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: space-around;
  div {
    p:last-child {
      font-size: 20px;
      font-weight: bold;
      color: @themeColor;
      margin-top: 10px;
    }
  }
}
.second {
  .el-input {
    width: 280px;
  }
}
.tips {
  color: @priceRed;
}
.agreen {
  font-size: 12px;
  span {
    color: @themeColor;
    cursor: pointer;
  }
}
.group {
  .el-radio {
    display: block;
    margin-top: 12px;
  }
}
.item_wrap {
  flex-wrap: wrap;
  .item {
    width: 135px;
    height: 30px;
    border: 1px solid #333333;
    border-radius: 4px;
    margin-right: 15px;
    margin-top: 15px;
    cursor: pointer;
    &.active {
      border: 1px solid @themeColor;
      color: @themeColor;
    }
  }
}
.rules {
  color: #333;
  line-height: 30px;
}
li::marker {
  color: #ff4c4c;
}
</style>
